import React from "react";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
} from "./InfoElements";
import {DynamicButton} from "../ButtonElements";

// const DEMO_URL = process.env.REACT_APP_DEMO_URL;

function InfoSection(props) {
  return (
    <>
      <InfoContainer id={props.id} lightBg={false}>
        <InfoWrapper>
          <InfoRow imgStart={props.imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{props.topLine}</TopLine>
                <Heading lightText={props.lightText}>{props.headline}</Heading>
                <Subtitle darkText={props.darkText}>
                  {props.description}
                </Subtitle>
                <BtnWrap>
                  {/*<Button*/}
                  {/*  to="SignIn"*/}
                  {/*  smooth={true}*/}
                  {/*  duration={500}*/}
                  {/*  spy={true}*/}
                  {/*  exact="true"*/}
                  {/*  offset={-80}*/}
                  {/*  primary={true}*/}
                  {/*  dark={props.dark ? 1 : 0}*/}
                  {/*  dark2={props.dark2 ? 1 : 0}*/}
                  {/*  darkText={true}*/}
                  {/*>*/}
                  {/*  {props.buttonLabel}*/}
                  {/*</Button>*/}
                  <DynamicButton
                      to="SignIn"
                      isExternal={false}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      primary={true}
                      dark={props.dark ? 1 : 0}
                      dark2={props.dark2 ? 1 : 0}
                      darkText={true}
                  >
                    {props.buttonLabel}
                  </DynamicButton>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                {props.video ? (
                    <video src={props.video} autoPlay loop muted />
                ) : (
                    <Img src={props.img} alt={props.alt} />
                )}
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
}

export default InfoSection;
