// import React, { useState } from "react";

import Video from "../../videos/gaia_hero_vid_2.mp4";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroSubTitle,
  HeroP
} from "./HeroElements";

function HeroSection() {
  // const [hover, setHover] = useState(false);
  // const onHover = () => {
  //   setHover((prev) => !prev);
  // };

  return (
    <>
      <HeroContainer id="home">
        <HeroBg>
          <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
        </HeroBg>
        <HeroContent>
          <HeroH1>Your Carbon Ledger, <br /> Gaia's Lifeline.</HeroH1>
          <HeroSubTitle>Gaia calculates your annual carbon footprint to meet regulatory standards and helps you reduce them.
          </HeroSubTitle>
            <HeroP>

          </HeroP>
          {/*<HeroBtnWrapper>*/}
          {/*  <Button*/}
          {/*    to="signup"*/}
          {/*    smooth={true}*/}
          {/*    duration={500}*/}
          {/*    spy={true}*/}
          {/*    exact="true"*/}
          {/*    offset={-80}*/}
          {/*    primary*/}
          {/*    dark*/}
          {/*    onMouseEnter={onHover}*/}
          {/*    onMouseLeave={onHover}*/}
          {/*  >*/}
          {/*    How it works {hover ? <ArrowForward /> : <ArrowRight />}*/}
          {/*  </Button>*/}
          {/*</HeroBtnWrapper>*/}
        </HeroContent>
      </HeroContainer>
    </>
  );
}

export default HeroSection;
