import React from "react";

import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
} from "./FooterElements";

function Footer() {
  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <FooterContainer id="Footer">
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            {/*<FooterLinkItems>*/}
              {/*<FooterLinkTitle>About Us</FooterLinkTitle>*/}
              {/*<FooterLink to="/signin">How it works</FooterLink>*/}
              {/*<FooterLink to="/signin">Sign in</FooterLink>*/}
            {/*</FooterLinkItems>*/}
            <FooterLinkItems>
              <FooterLinkTitle>Contact</FooterLinkTitle>
              <FooterLink to="/signin">sales@sustaingaia.com</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Follow us</FooterLinkTitle>
              <FooterLink to="/signin">Twitter</FooterLink>
              <FooterLink to="/signin">LinkedIn</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              sustainGaia
            </SocialLogo>
            <WebsiteRights>
              sustainGaia &copy; {new Date().getFullYear()} All Rights Reserved.
            </WebsiteRights>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
}

export default Footer;
