// import car from "../../images/svg-1.svg";
import piggybank from "../../images/Seamless-Collection-Integration.png";
import signup from "../../images/Precision-Matching.png";
import world from "../../images/Informed-Decision-Making.png";
import { Link } from 'react-scroll';
import missionVideo from "../../videos/mission_video_1.mp4";
import React from "react";

export const homeObjOne = {
  id: "about",
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: " ",
  headline: "Our Mission",
  description:
    "To empower carbon emissions reduction by precise impact measurement and immediate strategies for a sustainable future.",
  // buttonLabel: <a href={process.env.REACT_APP_DEMO_URL} target="_blank" rel="noopener noreferrer">Book a Demo</a>,
  buttonLabel: (
      <Link to="services" smooth={true} duration={500}>
        How it Works
      </Link>
  ),
  imgStart: true,
  video: missionVideo,
  alt: "Mission",
  dark: false,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "services",
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Step One",
  headline: "Seamless Collection & Integration",
  description:
    "Effortlessly upload your data and seamlessly integrate it with your current systems via Gaia, streamlining your sustainability efforts.",
  buttonLabel: (
      <Link to="services1" smooth={true} duration={500}>
        Step Two
      </Link>
  ),
  imgStart: true,
  img: piggybank,
  alt: "Piggybank",
  dark: false,
  primary: true,
  darkText: false,
};

export const homeObjThree = {
  id: "services1",
  lightBg: true,
  lightText: true,
  lightTextDesc: false,
  topLine: "Step Two",
  headline: "Precision Matching",
  description:
    "Your data is meticulously aligned with the global GHG emissions database, enabling us to accurately quantify your carbon footprint.",
  buttonLabel: (
      <Link to="services2" smooth={true} duration={500}>
        Step Three
      </Link>
  ),
  imgStart: false,
  img: signup,
  alt: "Papers",
  dark: false,
  primary: true,
  darkText: false,
};
export const homeObjFour = {
  id: "services2",
  lightBg: true,
  lightText: true,
  lightTextDesc: false,
  topLine: "Step Three",
  headline: "Informed Decision-Making",
  description:
      "With your data organized and visualized by category, scope, and industry, we empower you to take informed, strategic steps towards sustainability.",
  isExternal: true,
  to: process.env.REACT_APP_DEMO_URL,
  buttonLabel: (
      <Link to={process.env.REACT_APP_DEMO_URL}>
        Book a Demo
      </Link>
      // <InfoBtn>
      //   <InfoBtnLink to={process.env.REACT_APP_DEMO_URL}>Book a Demo</InfoBtnLink>
      // </InfoBtn>
  ),
  imgStart: true,
  img: world,
  alt: "World",
  dark: false,
  primary: true,
  darkText: false,
};